.art-header {
  width: 100%;
  padding: 10px;
  border-bottom: 0.05em solid rgba(130, 130, 130, 0.5);
  font-size: 18px;
}

.arrow {
  width: 40px;
  height: 30px;
  background: url("/src/assets/arrow.png") center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
