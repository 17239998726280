.color-grey {
  color: #828282;
}
.color-grey-hover:hover {
  color: #636363;
}
.bg-header {
  background: #e8d3d3;
}

.bg-green {
  background: #27ae60;
}

.bg-purple {
  background: #9b51e0;
}

.bg-blue {
  background: #6ebbe7;
}
