.invitation-carousel {
  overflow: hidden;
  width: 100%;
}

.invitation-inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.invitation-carousel-item {
  display: inline-flex;
  height: 100%;
}

.invitation-indicators {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invitation-indicators-item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background: rgba(0, 0, 0, 0.3);
}
.indicator-active {
  background: rgba(0, 0, 0, 0.7);
}
