.d-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.invitation {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
}

.invitation-frame {
  width: 50%;
  margin: auto;
  background: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0.3em 0.3em 8px rgba(0, 0, 0, 0.1);
}

.invitation button {
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  border: none;
  outline: none;
  width: 200px;
  transition: 0.5s;
}

.invitation button:hover {
  filter: brightness(70%);
}

.carousel-arrow {
  transition: 0.5s;
}

.carousel-arrow:hover {
  color: rgba(0, 0, 0, 0.8) !important;
}

@media only screen and (max-width: 600px) {
  .invitation-frame {
    width: 100% !important;
  }
  .invitation button {
    width: 100%;
  }
}
