.item {
  border-radius: 10px;
  box-shadow: 0.3em 0.3em 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.item-auto {
  height: 280px !important;
}

@media only screen and (max-width: 600px) {
  .item-auto {
    height: 140px !important;
  }
}
